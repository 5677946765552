<template>
  <div>
    <v-form>
      <p class="mb-3">How many equivalent carbons are there in compound D?</p>
      <p>
        number of equivalent carbons
        <calculation-input v-model="inputs.studentAnswer" class="mb-5" :disabled="!allowEditing" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI51LBA2_M4Q3b',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
};
</script>
